<template>
  <base-dialog
    type="block"
    btn_text="Editar"
    title="Servicios"
    icon=""
    @open="fetchService"
  >
    <v-row>
      <v-spacer />
      <AddService @update="fetchService" />
    </v-row>
    <v-row>
      <v-col
        v-for="(s, i) in services"
        cols="6"
        md="6"
        :key="`col-${i}`"
        class="px-1"
      >
        <SubServicesList :service="s" @update="fetchService" />
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "EditarDialog",
  components: {
    AddService: () =>
      import("@/components/user/settings/configuracion/services/Add"),
    SubServicesList: () =>
      import(
        "@/components/user/settings/configuracion/subservices/SubServicesList"
      ),
  },
  data() {
    return {
      services: [],
    };
  },
  methods: {
    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({}).then((response) => {
        this.services = response.data;
      });
    },
  },
};
</script>